import { createInstallation, getInstallationByName, updateInstallation } from '@api/installations';
import type { RequestErrorBody } from '@hooks/useRequest';
import { useMutation, useQuery, useQueryClient, type QueryClient } from '@tanstack/react-query';
import type { InstallationWithMeterInfo } from '@utils/types/installation';
import type { AxiosError } from 'axios';
import type { InstallationFormRequestBody, StepKey } from './installation.types';
import { formatInstallationBody } from './utils/formatInstallationBody';

type MutationContext = { step?: StepKey; status?: InstallationFormRequestBody['status'] };

export const useInstallationFormQuery = (name?: string) => {
  const queryClient = useQueryClient();
  const query = useQuery<InstallationWithMeterInfo, AxiosError<RequestErrorBody>>({
    queryKey: ['installation-name'],
    queryFn: () => getInstallationByName(name),
    enabled: !!name,
  });
  const internalName = query.data?.name || name;

  const createMutation = useMutation<
    InstallationWithMeterInfo,
    AxiosError<RequestErrorBody>,
    InstallationFormRequestBody,
    MutationContext
  >({
    mutationFn: (body) => createInstallation(formatInstallationBody(body)),
    onSuccess: (data) => onSuccess(data, queryClient),
  });

  const updateMutation = useMutation<
    InstallationWithMeterInfo,
    AxiosError<RequestErrorBody>,
    InstallationFormRequestBody,
    MutationContext
  >({
    mutationFn: (body) => updateInstallation(internalName || '', formatInstallationBody(body)),
    onSuccess: (data) => onSuccess(data, queryClient),
  });

  return { query, createMutation, updateMutation };
};

const onSuccess = (data: InstallationWithMeterInfo, queryClient: QueryClient) => {
  queryClient.setQueryData(['installation-name'], data);
};
