import React from 'react';
import { RadioButtons } from '@GDM/forms';
import { useSelectedGrid } from '@pages/Installations/Installation/context/useSelectedGrid.context';
import { useInstallationField } from '@pages/Installations/Installation/hooks/useInstallationField';
import { useInstallationForm } from '@pages/Installations/Installation/hooks/useInstallationForm';
import { Controller } from 'react-hook-form';

export const IpCheckbox = () => {
  const form = useInstallationForm();
  const { rules, disabled } = useInstallationField('boitier_ip');
  const { selectedGrid } = useSelectedGrid();

  const DEFAULT_TRUE_FOR_GRIDS = ['MOXA'];
  const DISABLED_FOR_GRIDS = ['MOXA'];

  return (
    <Controller
      control={form.control}
      name="boitier_ip"
      rules={rules}
      defaultValue={DEFAULT_TRUE_FOR_GRIDS.includes(selectedGrid?.name || '') || false}
      render={({ field }) => (
        <RadioButtons
          selected={field.value}
          options={[
            { label: 'admin.installations.ip_checkbox', value: true },
            { label: 'admin.installations.meter_phone_number_label', value: false },
          ]}
          onChange={field.onChange}
          data-cy="meter-ip-toggle"
          label="admin.installations.meter_channel_label"
          disabled={DISABLED_FOR_GRIDS.includes(selectedGrid?.name || '') || disabled}
        />
      )}
    />
  );
};
