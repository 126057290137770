import React, { useEffect, useMemo } from 'react';
import { useUser } from '@context/User.context';
import { Input, RadioButtons, Section, sectionStyles } from '@GDM/forms';
import { SECTIONS_PER_CONTRACT_TYPES } from '@pages/Contracts/Contract/hooks/useContractForm/constants';
import { CFD_CONTRACTS } from '@utils/constants/contractTypesList';
import { getSymbol } from '@utils/currency/getSymbol';
import { Option } from '@utils/types/common-types';
import IndexFormula from '@utils/types/IndexFormula';
import classNames from 'classnames';
import { ContractFormSectionProps, FormSectionLayout } from '../../components/FormSection/FormSectionLayout';
import { SectionHeader } from '../../components/SectionHeader';
import { CONTRACT_TYPES_WITH_EDF_FORMULAS } from '../../constants/contract-types-with-edf-formulas';
import { AnnualIndexation } from './components/AnnualIndexation';
import { CapaPricing } from './components/CapaPricing';
import { DetailedIndexFormulaPicker, IndexFormulaPicker, ManagementBonus } from './components/IndexFormula';
import { RefPrice, RateChange, RefPriceWithRateChange } from './components/RefPrice';
import {
  CFD_CONTRACTS_INDEXATION_FORMULAS,
  CFD_CONTRACTS_INDEXATION_FORMULAS_MAP,
  HYDRONEXT_INDEXATION,
} from './constants';

export const Pricing = ({
  title,
  readOnly,
  queries: { indexFormulas, marketPlayers },
  form: { control, watch, setValue },
}: ContractFormSectionProps) => {
  const [startDate, endDate, contractType, currency, id, subType, sellerId, buyerId] = watch([
    'start_date',
    'end_date',
    'type',
    'currency',
    'id',
    'sub_type',
    'seller_id',
    'buyer_id',
  ]);

  const { account_type: accountType } = useUser();

  const isSellerHydronext = useMemo(() => {
    const id = accountType === 'aggregator' ? buyerId : sellerId;

    const seller = marketPlayers.data?.find((mp) => mp.id === id);

    if (!seller) return false;

    return seller.long_name.toLowerCase() === 'hydronext';
  }, [sellerId, buyerId, accountType, marketPlayers.data]);

  useEffect(() => {
    if (contractType && !SECTIONS_PER_CONTRACT_TYPES[contractType].includes('sub-periods'))
      setValue('contract_sub_periods_attributes.0.dates', [startDate, endDate]);
  }, [endDate, setValue, startDate, contractType]);

  const displayAnnualIndexationData =
    readOnly && contractType && ['ContractCrEdf', 'ContractOa'].includes(contractType);
  const aboveCapPrice = watch('above_cap_price');
  const isAboveCapPriceIndexed = watch('is_indexed');

  const hasManagementBonus =
    readOnly &&
    ((contractType === 'ContractOa' && ['cr16', 'cr17'].includes(subType || '')) ||
      (contractType === 'ContractCrEdf' && ['E16-V1', 'E17_V1.0.1', 'E17_V1.0.2', 'E17_V2'].includes(subType || '')));

  const showIndexedPricing = isAboveCapPriceIndexed != null && aboveCapPrice !== null;

  const indexFormulaOptions: Option<IndexFormula['formula']>[] = useMemo(() => {
    if (!indexFormulas.data) return [];

    const { edfFormulas, formulas } = indexFormulas.data;

    if (contractType === 'ContractSupplyAuxiliaries' && isSellerHydronext) {
      // Only formula for hydronext

      const formula = formulas.find((formula) => formula.formula === HYDRONEXT_INDEXATION);

      if (!formula) return [];

      return [{ label: formula.formula, value: formula.formula }];
    }

    if (contractType && CFD_CONTRACTS.includes(contractType)) {
      const formula = CFD_CONTRACTS_INDEXATION_FORMULAS_MAP[contractType];

      return [{ label: formula, value: formula }];
    }

    const filteredFormulaList = CONTRACT_TYPES_WITH_EDF_FORMULAS.includes(contractType)
      ? edfFormulas
      : formulas.filter(({ formula }) => !CFD_CONTRACTS_INDEXATION_FORMULAS.includes(formula));

    return (
      filteredFormulaList?.map(({ formula }) => ({
        label: formula,
        value: formula,
      })) || []
    );
  }, [contractType, indexFormulas.data, isSellerHydronext]);

  useEffect(() => {
    if (indexFormulaOptions.length === 1) {
      setValue('index_formula', indexFormulaOptions[0].value);
    }
  }, [indexFormulaOptions, indexFormulaOptions.length, setValue]);

  const contractsWithRateChange = ['ContractSupplyAuxiliaries', ...CFD_CONTRACTS];
  const contractsWithRefPriceAndIndexation = [
    'ContractCrEdf',
    'ContractOa',
    'ContractSupplyAuxiliaries',
    ...CFD_CONTRACTS,
  ];

  const simpleRefPriceAndIndexation = contractsWithRefPriceAndIndexation.includes(contractType || '') && (
    <div className={sectionStyles.row}>
      {['ContractCrEdf', 'ContractOa'].includes(contractType || '') && (
        <RefPrice control={control} readOnly={readOnly} currency={currency} />
      )}
      {contractsWithRateChange.includes(contractType || '') && (
        <RateChange
          control={control}
          readOnly={readOnly}
          options={
            contractType === 'ContractSupplyAuxiliaries' && isSellerHydronext
              ? [{ value: '1st_nov', label: 'sales_management.1st_nov' }]
              : undefined
          }
        />
      )}
      <IndexFormulaPicker
        control={control}
        options={indexFormulaOptions}
        isLoading={indexFormulas.isPending}
        readOnly={readOnly}
        defaultValue={indexFormulaOptions.length <= 1 ? indexFormulaOptions[0]?.value : undefined}
        isDisabled={indexFormulaOptions.length <= 1}
      />
      {hasManagementBonus && <ManagementBonus control={control} currency={currency} />}
    </div>
  );

  const birthdayDate = watch('birthday_date');
  const hasDetailedRefPriceAndIndexation = ['ContractOa'].includes(contractType || '');
  const detailedRefPriceAndIndexation = hasDetailedRefPriceAndIndexation && (
    <>
      <RefPriceWithRateChange
        control={control}
        readOnly={readOnly}
        currency={currency}
        birthdayDate={birthdayDate || null}
      />
      <DetailedIndexFormulaPicker
        control={control}
        options={indexFormulaOptions}
        isLoading={indexFormulas.isPending}
        readOnly={readOnly}
        currency={currency}
        hasManagementBonus={hasManagementBonus}
      />
    </>
  );

  const refPriceAndIndextion = detailedRefPriceAndIndexation || simpleRefPriceAndIndexation;

  const contractsWithCapaPricing = ['ContractCapa'];
  const capaPricing = contractsWithCapaPricing.includes(contractType || '') && (
    <CapaPricing control={control} readOnly={readOnly} currency={currency} />
  );

  return (
    <FormSectionLayout
      title={title}
      headerActions={<SectionHeader />}
      body={
        <Section className="p-3">
          {refPriceAndIndextion}
          {capaPricing}
          {readOnly && showIndexedPricing && (
            <div className={classNames(sectionStyles.row, 'flex')}>
              <Input
                label="contracts.capped_price"
                size="lg"
                tooltip="contracts.capped_price_details"
                type="number"
                autoComplete="off"
                readOnly={readOnly}
                full
                value={aboveCapPrice}
                suffix={`${getSymbol(currency)}/MWh`}
              />
              <RadioButtons
                size="lg"
                readOnly
                selected={isAboveCapPriceIndexed}
                label="contracts.capped_price_indexation"
                options={[
                  { label: 'common.yes', value: true },
                  { label: 'common.no', value: false },
                ]}
              />
            </div>
          )}
          {displayAnnualIndexationData && id && <AnnualIndexation contractId={id} currency={currency} />}
        </Section>
      }
    />
  );
};
