import React, { useState } from 'react';
import { useConsumeTrades } from '@context/trades.context';
import { Table, TableActions, TableBody, TableHead, TablePageSizeSelect, TablePagination } from '@GDM/Table';
import { getPaginationRowModel, getSortedRowModel, useReactTable, getCoreRowModel } from '@tanstack/react-table';
import styles from '../trades.module.scss';
import { useColumns } from './useColumns';

export const TradesTable = () => {
  const [pagination, setPagination] = useState({ pageIndex: 0, pageSize: 50 });

  const { allTrades } = useConsumeTrades();

  const { columns } = useColumns();

  const table = useReactTable({
    columns,
    data: allTrades,
    sortDescFirst: false,
    getCoreRowModel: getCoreRowModel(),
    getSortedRowModel: getSortedRowModel(),
    getPaginationRowModel: getPaginationRowModel(),
    onPaginationChange: setPagination,
    state: { pagination },
    initialState: { sorting: [{ id: 'installation.name', desc: false }] },
  });

  const pageSize = pagination.pageSize;
  const pageCount = table.getPageCount();

  return (
    <div className={styles['table-container']}>
      <Table data-cy="trades-table">
        <TableHead table={table} />
        <TableBody table={table} />
      </Table>

      {allTrades.length > pageSize && (
        <TableActions className="p-0">
          <TablePageSizeSelect pageSize={pageSize} setPageSize={table.setPageSize} totalEntries={allTrades.length} />
          <TablePagination pageCount={pageCount} gotoPage={table.setPageIndex} />
        </TableActions>
      )}
    </div>
  );
};
