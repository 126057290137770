import { useState } from 'react';
import { v2_installations_path } from '@utils/routes';
import { stepKeys } from '../constants/steps';
import { InstallationForm, InstallationFormRequestBody, StepKey } from '../installation.types';

type ExtraState = {
  showMeterForm: boolean;
  isDraft: boolean;
  isDirty: boolean;
  dataProviderName?: string;
  meterId?: number;
};
type WorkflowHookException = { exit?: boolean; targetState?: StepKey; error?: string };

type InstallationFormStep = {
  preMutation?: (values: InstallationForm, state: ExtraState) => WorkflowHookException | undefined;
  mutation?: (data: InstallationFormRequestBody, state: ExtraState) => Promise<any>;
  postMutation?: (values: InstallationForm, state: ExtraState) => WorkflowHookException | undefined;
};

export const useInstallationFormWorkflow = (
  workflowSteps: Record<StepKey, InstallationFormStep>,
  setError: (error: string) => void,
  clearErrors: () => void,
) => {
  const [currentStep, setCurrrentStep] = useState<StepKey>(stepKeys[0]);
  const [previousSteps, setPreviousSteps] = useState<StepKey[]>([]);

  const gotToStep = (step: StepKey) => {
    clearErrors();
    setCurrrentStep(step);
  };

  const workflowHookExceptionHandler: (exception: WorkflowHookException) => void = ({ exit, targetState, error }) => {
    if (error) {
      setError(error);
    }

    if (exit) {
      window.location.href = v2_installations_path();

      return;
    }

    if (targetState) {
      setPreviousSteps([...previousSteps, currentStep]);
      gotToStep(targetState);
    }
  };

  const goToNextStep = async (values: InstallationForm, state: ExtraState) => {
    const currentWorkflowStep = workflowSteps[currentStep];
    const { preMutation, mutation, postMutation } = currentWorkflowStep;

    const preMutationException = preMutation?.(values, state);
    if (preMutationException) {
      workflowHookExceptionHandler(preMutationException);

      return;
    }

    const hasDataToSend = values.uuid ? state.isDirty : true;

    if (hasDataToSend) {
      await mutation?.(
        {
          ...values,
          status: state.isDraft ? 'draft' : undefined,
        },
        state,
      );
    }

    const postMutationException = postMutation?.(values, state);
    if (postMutationException) {
      workflowHookExceptionHandler(postMutationException);

      return;
    }

    const currentStepIndex = stepKeys.indexOf(currentStep);
    const nextStep = stepKeys[currentStepIndex + 1];

    if (nextStep) {
      setPreviousSteps([...previousSteps, currentStep]);
      gotToStep(nextStep);
    }
  };

  const goToPreviousStep = () => {
    gotToStep(previousSteps[previousSteps.length - 1]);
    setPreviousSteps(previousSteps.slice(0, -1));
  };

  return { currentStep, goToNextStep, goToPreviousStep };
};
