import { useUser } from '@context/User.context';
import {
  ALL_REGULAR_CHART_METRICS,
  ALL_SPLIT_BY_ONLY_METRICS,
  VALUATION_MAIN_VOLUME,
  SPLIT_BY_METRICS_ONLY_LINES,
  REGULAR_COLUMN_METRICS,
  SPLIT_BY_METRICS_ONLY_COLUMNS,
  CR_METRICS,
  VALUATION_VOLUME_METRICS_ORDER_MAP,
  FOCUS_VALUE_TO_LABEL,
} from '@pages/RiskAnalysis/constants';
import { getUnitFromMetric } from '@pages/RiskAnalysis/utils';
import Highcharts from 'highcharts/highstock';
import {
  GreenstarFocusData,
  GreenstarReportData,
  GreenstarReportDataWithOneFocus,
  GreenstarReportMetricValues,
} from '../../types';
import useTranslation, { TranslateFn } from '@hooks/useTranslation';

export type RiskAnalysisChart = {
  key: string;
  series: Highcharts.Options['series'];
  options: Highcharts.Options;
};

export const useRiskAnalysisCharts = ({
  data,
  isReportNested,
  splitByMetric,
  metricsBlackList,
}: {
  data: GreenstarReportData;
  isReportNested: boolean;
  splitByMetric: boolean;
  metricsBlackList: string[];
}): RiskAnalysisChart[] => {
  const { locale } = useUser();
  const { t } = useTranslation();

  const localizedColumn = `${locale === 'fr' ? ' ' : ''}:`;

  return isReportNested
    ? createDoubleLevelFocusBasedChart(data, splitByMetric, metricsBlackList, t)
    : createSingleLevelFocusBasedChart(data, splitByMetric, metricsBlackList, localizedColumn, t);
};

const createSingleLevelFocusBasedChart = (
  data: GreenstarReportData,
  splitByMetric: boolean,
  metricsBlackList: string[],
  localizedColumn: string,
  t: TranslateFn,
) => {
  const VALID_METRICS = splitByMetric
    ? [...ALL_REGULAR_CHART_METRICS, ...ALL_SPLIT_BY_ONLY_METRICS, ...VALUATION_MAIN_VOLUME]
    : ALL_REGULAR_CHART_METRICS;

  const COLUMN_METRICS = splitByMetric
    ? [...REGULAR_COLUMN_METRICS, ...SPLIT_BY_METRICS_ONLY_COLUMNS, ...VALUATION_MAIN_VOLUME]
    : REGULAR_COLUMN_METRICS;

  const charts: RiskAnalysisChart[] = [];

  Object.entries(data).forEach(([focusValue, dateIndexedData]: [string, GreenstarFocusData]) => {
    const dates = Object.keys(dateIndexedData).sort();
    const seriesMap = new Map<string, number[]>();
    dates.forEach((date) => {
      Object.entries(dateIndexedData[date]).forEach(([metric, value]) => {
        const actualMetric = splitByMetric ? focusValue : metric;
        if (!VALID_METRICS.includes(actualMetric)) return;
        if (focusValue === 'CR' && !CR_METRICS.includes(actualMetric)) return;

        let serie = seriesMap.get(metric);
        if (!serie) {
          serie = [];
          seriesMap.set(metric, serie);
        }

        serie.push(Number(value));
      });
    });

    let useFirstAxis = false;
    let useSecondAxis = false;
    const series = [...seriesMap.entries()]
      .map(([metric, data]) => {
        const actualMetric = splitByMetric ? focusValue : metric;

        useSecondAxis = useSecondAxis || Number(COLUMN_METRICS.includes(actualMetric)) === 0;
        useFirstAxis = useFirstAxis || Number(COLUMN_METRICS.includes(actualMetric)) === 1;

        return {
          name: metric,
          data,
          yAxis: Number(COLUMN_METRICS.includes(actualMetric)),
          type: COLUMN_METRICS.includes(actualMetric) ? 'column' : 'line',
          zIndex: COLUMN_METRICS.includes(actualMetric) ? 1 : 2,
        };
      })
      .sort((a, b) => {
        const indexA = VALUATION_VOLUME_METRICS_ORDER_MAP.get(a.name);
        const indexB = VALUATION_VOLUME_METRICS_ORDER_MAP.get(b.name);

        if (indexA === undefined) return 1;
        if (indexB === undefined) return -1;

        return indexA - indexB;
      })
      .filter((serie) => {
        return splitByMetric ? true : !metricsBlackList.includes(serie.name);
      }) as Highcharts.Options['series'];

    const firstAxisInEuro = splitByMetric && SPLIT_BY_METRICS_ONLY_LINES.includes(focusValue);
    const focusLabel = t(FOCUS_VALUE_TO_LABEL[focusValue] || focusValue);

    charts.push({
      series,
      key: focusValue,
      options: {
        title: {
          text: focusLabel,
          align: 'left',
          style: {
            fontSize: '20px',
            fontWeight: 'bold',
          },
        },
        xAxis: {
          type: 'category',
          categories: dates,
        },
        tooltip: {
          formatter: function () {
            let tooltip = '<b>' + this.x + '</b><br>';
            let total = 0;
            let total_mtom = 0;

            (this.points || []).forEach((point) => {
              const name = splitByMetric ? focusValue : point.series.name;

              const unit = getUnitFromMetric(name);

              tooltip +=
                '<span style="color:' +
                point.color +
                '">\u25CF</span> ' +
                point.series.name +
                `${localizedColumn} <b>` +
                Highcharts.numberFormat(point.y, 2) +
                ' ' +
                unit +
                '</b><br>';
              if (point.series.name.includes('Volume') || focusValue.includes('Volume')) {
                total += point.y;
              }
              if (focusValue === 'MtoM') {
                total_mtom += point.y;
              }
            });

            if (total > 0)
              tooltip += `<br><b>Total Volume${localizedColumn} ` + Highcharts.numberFormat(total, 2) + ' GWh</b>';
            if (total_mtom > 0)
              tooltip += `<br><b>Total MtoM${localizedColumn} ` + Highcharts.numberFormat(total_mtom, 2) + ' M€</b>';

            return tooltip;
          },
          shared: true,
        },
        yAxis: [
          firstAxisInEuro
            ? {
                title: { text: null },
                labels: { format: '{value} €/MWh' },
                tickInterval: 50,
                minorTickInterval: 10,
                visible: true,
                opposite: !useFirstAxis,
              }
            : {
                title: { text: null },
                min: 0,
                max: 100,
                labels: { format: '{value} %' },
                visible: useSecondAxis,
                opposite: !useFirstAxis,
              },
          {
            title: { text: null },
            opposite: true,
          },
        ],
        plotOptions: {
          column: {
            minPointLength: 3,
            stacking: 'normal',
          },
        },
      },
    });
  });

  return splitByMetric
    ? charts.filter(({ key }) => VALID_METRICS.includes(key) && !metricsBlackList.includes(key))
    : charts;
};

const createDoubleLevelFocusBasedChart = (
  data: GreenstarReportData,
  splitByMetric: boolean,
  metricsBlackList: string[],
  t: TranslateFn,
) => {
  const charts: RiskAnalysisChart[] = [];
  const VALID_METRICS = splitByMetric
    ? [...ALL_REGULAR_CHART_METRICS, ...ALL_SPLIT_BY_ONLY_METRICS]
    : ALL_REGULAR_CHART_METRICS;

  const COLUMN_METRICS = splitByMetric
    ? [...REGULAR_COLUMN_METRICS, ...SPLIT_BY_METRICS_ONLY_COLUMNS]
    : REGULAR_COLUMN_METRICS;

  Object.entries(data).forEach(([focusValue, groupOfDateIndexedData]: [string, GreenstarReportDataWithOneFocus]) => {
    if (splitByMetric && focusValue === 'HCR') return;

    const dateIndexedDataList = Object.entries(groupOfDateIndexedData);

    const dates = Object.keys(
      dateIndexedDataList.reduce((acc, [, value]) => ({ ...acc, ...value }), {} as GreenstarFocusData),
    ).sort();

    const valuesList: GreenstarReportMetricValues[] = [];

    dates.forEach((date) => {
      const values: GreenstarReportMetricValues = {};

      dateIndexedDataList.forEach(([nestedKey, dateIndexedData]) => {
        if (!dateIndexedData[date]) return;
        Object.entries(dateIndexedData[date]).forEach(([metric, value]) => {
          const actualMetric = splitByMetric ? nestedKey : metric;
          values[actualMetric] = Number(values[actualMetric] || 0) + Number(value);
        });
      });

      valuesList.push(values);
    });

    const seriesMap = new Map<string, number[]>();
    const hcrSerie: (number | null)[] = [];
    seriesMap.set('HCR', hcrSerie as number[]);

    valuesList.forEach((values) => {
      Object.entries(values).forEach(([metric, value]) => {
        const actualMetric = splitByMetric ? focusValue : metric;
        // We'll compute HCR ourselves
        if (!VALID_METRICS.includes(actualMetric) || actualMetric == 'HCR') return;
        if (focusValue === 'CR' && !CR_METRICS.includes(actualMetric)) return;

        let serie = seriesMap.get(metric);
        if (!serie) {
          serie = [];
          seriesMap.set(metric, serie);
        }

        serie.push(Number(value));
      });
      const isClickedVolumeDefined = typeof values['Clicked Volume'] !== 'undefined';
      const isVolumeDefined = typeof values['Volume'] !== 'undefined';
      const isHcrComputable = isClickedVolumeDefined && isVolumeDefined;
      const hcr = isHcrComputable ? (100 * Number(values['Clicked Volume'])) / Number(values['Volume']) : null;
      hcrSerie.push(hcr);
    });

    if (hcrSerie.every((hcr) => hcr === null)) {
      seriesMap.delete('HCR');
    }

    let useFirstAxis = false;
    let useSecondAxis = false;
    const series = [...seriesMap.entries()]
      .map(([metric, data]) => {
        const actualMetric = splitByMetric ? focusValue : metric;
        useSecondAxis = useSecondAxis || Number(COLUMN_METRICS.includes(actualMetric)) === 0;
        useFirstAxis = useFirstAxis || Number(COLUMN_METRICS.includes(actualMetric)) === 1;

        return {
          name: metric,
          data,
          yAxis: Number(COLUMN_METRICS.includes(actualMetric)),
          type: COLUMN_METRICS.includes(actualMetric) ? 'column' : 'line',
          zIndex: COLUMN_METRICS.includes(actualMetric) ? 1 : 2,
          tooltip: {
            valueSuffix: getUnitFromMetric(actualMetric),
          },
        };
      })
      .sort((a, b) => {
        const indexA = VALUATION_VOLUME_METRICS_ORDER_MAP.get(a.name);
        const indexB = VALUATION_VOLUME_METRICS_ORDER_MAP.get(b.name);

        if (indexA === undefined) return 1;
        if (indexB === undefined) return -1;

        return indexA - indexB;
      })
      .filter((serie) => {
        return splitByMetric ? true : !metricsBlackList.includes(serie.name);
      }) as Highcharts.Options['series'];

    const firstAxisInEuro = splitByMetric && SPLIT_BY_METRICS_ONLY_LINES.includes(focusValue);
    const focusLabel = t(FOCUS_VALUE_TO_LABEL[focusValue] || focusValue);

    charts.push({
      series,
      key: focusValue,
      options: {
        title: { text: focusLabel, align: 'left' },
        xAxis: {
          type: 'category',
          categories: dates,
        },
        yAxis: [
          firstAxisInEuro
            ? {
                title: { text: null },
                labels: { format: '{value} €/MWh' },
                visible: true,
                opposite: !useFirstAxis,
              }
            : {
                title: { text: null },
                min: 0,
                max: 100,
                labels: { format: '{value} %' },
                visible: useSecondAxis,
                opposite: !useFirstAxis,
              },
          {
            title: { text: null },
            opposite: true,
          },
        ],
        plotOptions: {
          column: {
            minPointLength: 3,
            stacking: 'normal',
          },
        },
      },
    });
  });

  if (splitByMetric) {
    const splitByMetricCharts = charts.filter(
      ({ key }) => VALID_METRICS.includes(key) && !metricsBlackList.includes(key),
    );

    return splitByMetricCharts;
  }

  return charts;
};
