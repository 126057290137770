import React, { useState, useMemo } from 'react';
import { useMandatesContext } from '@context/Mandates.context';
import { Hr } from '@GDM/Hr';
import { Col, Row } from '@GDM/layout';
import { useInstallationForm } from '@pages/Installations/Installation/hooks/useInstallationForm';
import { useInstallationFormQuery } from '@pages/Installations/Installation/useInstallationFormQuery';
import { CompanyNumberInput } from './fields/CompanyNumberInput';
import { DownloadOriginalFile } from './fields/DownloadOriginalFile';
import { DownloadTemplateFile } from './fields/DownloadTemplateFile';
import { SignatoryEmailInput } from './fields/SignatoryEmailInput';
import { SignatoryNameInput } from './fields/SignatoryNameInput';
import { SignTypeSelect } from './fields/SignTypeSelect';
import { UploadFile } from './fields/UploadFile';
import MandateSelect from './MandateSelect/MandateSelect';

export const MandateSection = () => {
  const form = useInstallationForm();
  const country = form.watch('country');
  const signType = form.watch('mandate.sign_type');

  const { query } = useInstallationFormQuery();
  const installationCompanyNumber = query.data?.owner_company_number;

  const [isNewMandate, setIsNewMandate] = useState(false);
  const { data: mandates } = useMandatesContext();
  const mandateUuid = form.watch('mandate_uuid');
  const selectedMandate = useMemo(
    () => (mandates || []).find((mandate) => mandate.uuid === mandateUuid),
    [mandateUuid, mandates],
  );

  const openNewMandateForm = () => {
    setIsNewMandate(true);
    form.setValue('mandate_uuid', '');
    form.setValue('mandate.signatory_name', '');
    form.setValue('mandate.signatory_email', '');
    form.setValue('mandate.sirets', installationCompanyNumber || '');
    form.setValue('mandate.sign_type', null);
    form.setValue('mandate.mandate_file', null);
  };

  const showForm = !selectedMandate && isNewMandate;

  return (
    <div className="p-3">
      <MandateSelect openNewMandateForm={openNewMandateForm} isNewMandate={isNewMandate} />
      {selectedMandate && !!selectedMandate.url && (
        <Row className="my-1">
          <Col md={3}>{selectedMandate && <DownloadOriginalFile mandate={selectedMandate} />}</Col>
        </Row>
      )}
      {showForm && (
        <>
          <Hr />
          <Row className="my-1">
            <Col md={6}>
              <SignatoryNameInput disabled={!!selectedMandate} />
            </Col>
            <Col md={6}>
              <SignatoryEmailInput disabled={!!selectedMandate} />
            </Col>
          </Row>
          <Row className="my-1">
            <Col md={12}>
              <CompanyNumberInput disabled={!!selectedMandate} />
            </Col>
          </Row>
          <Row className="my-1">
            <Col md={12}>
              <SignTypeSelect disabled={!!selectedMandate} />
            </Col>
          </Row>
          <Row className="my-2">
            <Col md={12}>
              <UploadFile disabled={signType === 'online'} />
            </Col>
          </Row>
          <Row className="my-2">
            <Col md={3}>{selectedMandate && country === 'FR' && <DownloadTemplateFile />}</Col>
          </Row>
        </>
      )}
    </div>
  );
};
