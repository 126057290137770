import { Option } from '@utils/types/common-types';
import { ContractType, type Contract } from '@utils/types/contract';
import { CountryCode } from '@utils/types/countries';

/**
 * List of contract types based on installation country
 */
export const contractTypeList: Record<CountryCode | 'GLSF', ContractTypeOption[]> = {
  GLSF: [
    { value: 'ContractGo-LIGHT', label: 'GO/REC', direction: 'sell' },
    { value: 'ContractSoa-LIGHT', label: 'contracts.types.soa_light', direction: 'sell' },
  ],
  AT: [
    { value: 'ContractSoa', label: 'uPPA', direction: 'sell' },
    {
      value: 'ContractSupplyCppa',
      label: 'contracts.types.supply_cppa',
      direction: 'buy',
      permissions: ['display:power_supply'],
    },
    {
      value: 'ContractSupplyUppa',
      label: 'contracts.types.supply_uppa',
      direction: 'buy',
      permissions: ['display:power_supply'],
    },
    {
      value: 'ContractSupplyAuxiliaries',
      label: 'contracts.types.aux',
      direction: 'buy',
      disabled: true,
      permissions: ['display:power_supply'],
    },
  ],
  BE: [
    { value: 'ContractSoa', label: 'uPPA', direction: 'sell' },
    { value: 'ContractCppa', label: 'cPPA', direction: 'sell' },
    { value: 'ContractGo', label: 'GO', direction: 'sell' },
    { value: 'ContractGc', label: 'GC', direction: 'sell' },
    { value: 'ContractSwap', label: 'Swap', direction: 'sell' },
    {
      value: 'ContractSupplyCppa',
      label: 'contracts.types.supply_cppa',
      direction: 'buy',
      permissions: ['display:power_supply'],
    },
    {
      value: 'ContractSupplyUppa',
      label: 'contracts.types.supply_uppa',
      direction: 'buy',
      permissions: ['display:power_supply'],
    },
    {
      value: 'ContractSupplyAuxiliaries',
      label: 'contracts.types.aux',
      direction: 'buy',
      disabled: true,
      permissions: ['display:power_supply'],
    },
  ],
  BF: [
    { value: 'ContractSoa', label: 'uPPA', direction: 'sell' },
    {
      value: 'ContractSupplyCppa',
      label: 'contracts.types.supply_cppa',
      direction: 'buy',
      permissions: ['display:power_supply'],
    },
    {
      value: 'ContractSupplyUppa',
      label: 'contracts.types.supply_uppa',
      direction: 'buy',
      permissions: ['display:power_supply'],
    },
    {
      value: 'ContractSupplyAuxiliaries',
      label: 'contracts.types.aux',
      direction: 'buy',
      disabled: true,
      permissions: ['display:power_supply'],
    },
  ],
  BG: [
    { value: 'ContractSoa', label: 'uPPA', direction: 'sell' },
    {
      value: 'ContractSupplyCppa',
      label: 'contracts.types.supply_cppa',
      direction: 'buy',
      permissions: ['display:power_supply'],
    },
    {
      value: 'ContractSupplyUppa',
      label: 'contracts.types.supply_uppa',
      direction: 'buy',
      permissions: ['display:power_supply'],
    },
    {
      value: 'ContractSupplyAuxiliaries',
      label: 'contracts.types.aux',
      direction: 'buy',
      disabled: true,
      permissions: ['display:power_supply'],
    },
  ],
  CH: [
    { value: 'ContractSoa', label: 'uPPA', direction: 'sell' },
    { value: 'ContractCppa', label: 'cPPA', direction: 'sell' },
    { value: 'ContractGo', label: 'REC', direction: 'sell' },
    { value: 'ContractSwap', label: 'Swap', direction: 'sell' },
    {
      value: 'ContractSupplyCppa',
      label: 'contracts.types.supply_cppa',
      direction: 'buy',
      permissions: ['display:power_supply'],
    },
    {
      value: 'ContractSupplyUppa',
      label: 'contracts.types.supply_uppa',
      direction: 'buy',
      permissions: ['display:power_supply'],
    },
    {
      value: 'ContractSupplyAuxiliaries',
      label: 'contracts.types.aux',
      direction: 'buy',
      disabled: true,
      permissions: ['display:power_supply'],
    },
  ],
  CL: [
    { value: 'ContractSoa', label: 'uPPA', direction: 'sell' },
    {
      value: 'ContractSupplyCppa',
      label: 'contracts.types.supply_cppa',
      direction: 'buy',
      permissions: ['display:power_supply'],
    },
    {
      value: 'ContractSupplyUppa',
      label: 'contracts.types.supply_uppa',
      direction: 'buy',
      permissions: ['display:power_supply'],
    },
    {
      value: 'ContractSupplyAuxiliaries',
      label: 'contracts.types.aux',
      direction: 'buy',
      disabled: true,
      permissions: ['display:power_supply'],
    },
  ],
  DE: [
    { value: 'ContractSoa', label: 'Post-EEG (uPPA)', direction: 'sell' },
    { value: 'ContractCppa', label: 'cPPA', direction: 'sell' },
    { value: 'ContractGo', label: 'REC', direction: 'sell' },
    { value: 'ContractCrEdf', label: 'CfD EEG', direction: 'sell' },
    { value: 'ContractCr', label: 'CfD', direction: 'sell' },
    { value: 'ContractSwap', label: 'Swap', direction: 'sell' },
    {
      value: 'ContractSupplyCppa',
      label: 'contracts.types.supply_cppa',
      direction: 'buy',
      permissions: ['display:power_supply'],
    },
    {
      value: 'ContractSupplyUppa',
      label: 'contracts.types.supply_uppa',
      direction: 'buy',
      permissions: ['display:power_supply'],
    },
    {
      value: 'ContractSupplyAuxiliaries',
      label: 'contracts.types.aux',
      direction: 'buy',
      disabled: true,
      permissions: ['display:power_supply'],
    },
  ],
  DO: [
    { value: 'ContractSoa', label: 'uPPA', direction: 'sell' },
    {
      value: 'ContractSupplyCppa',
      label: 'contracts.types.supply_cppa',
      direction: 'buy',
      permissions: ['display:power_supply'],
    },
    {
      value: 'ContractSupplyUppa',
      label: 'contracts.types.supply_uppa',
      direction: 'buy',
      permissions: ['display:power_supply'],
    },
    {
      value: 'ContractSupplyAuxiliaries',
      label: 'contracts.types.aux',
      direction: 'buy',
      disabled: true,
      permissions: ['display:power_supply'],
    },
  ],
  ES: [
    { value: 'ContractSoa', label: 'uPPA', direction: 'sell' },
    { value: 'ContractCppa', label: 'cPPA', direction: 'sell' },
    { value: 'ContractGo', label: 'GdO', direction: 'sell' },
    { value: 'ContractSwap', label: 'Swap', direction: 'sell' },
    {
      value: 'ContractSupplyCppa',
      label: 'contracts.types.supply_cppa',
      direction: 'buy',
      permissions: ['display:power_supply'],
    },
    {
      value: 'ContractSupplyUppa',
      label: 'contracts.types.supply_uppa',
      direction: 'buy',
      permissions: ['display:power_supply'],
    },
    {
      value: 'ContractSupplyAuxiliaries',
      label: 'contracts.types.aux',
      direction: 'buy',
      disabled: true,
      permissions: ['display:power_supply'],
    },
  ],
  FI: [
    { value: 'ContractSoa', label: 'uPPA', direction: 'sell' },
    { value: 'ContractCppa', label: 'cPPA', direction: 'sell' },
    {
      value: 'ContractSupplyCppa',
      label: 'contracts.types.supply_cppa',
      direction: 'buy',
      permissions: ['display:power_supply'],
    },
    {
      value: 'ContractSupplyUppa',
      label: 'contracts.types.supply_uppa',
      direction: 'buy',
      permissions: ['display:power_supply'],
    },
    {
      value: 'ContractSupplyAuxiliaries',
      label: 'contracts.types.aux',
      direction: 'buy',
      disabled: true,
      permissions: ['display:power_supply'],
    },
  ],
  FR: [
    { value: 'ContractOa', label: 'contracts.types.oa', direction: 'sell' },
    { value: 'ContractCrEdf', label: 'contracts.types.cr_edf', direction: 'sell' },
    { value: 'ContractCr', label: 'contracts.types.cr', direction: 'sell' },
    { value: 'ContractSoa', label: 'contracts.types.soa', direction: 'sell' },
    { value: 'ContractCppa', label: 'contracts.types.cppa', direction: 'sell' },
    { value: 'ContractCapaProxy', label: 'contracts.types.capa_proxy', direction: 'sell' },
    { value: 'ContractCapa', label: 'contracts.types.capa', direction: 'sell' },
    { value: 'ContractGo', label: 'contracts.types.go', direction: 'sell' },
    { value: 'ContractSwap', label: 'contracts.types.swap', direction: 'sell' },
    {
      value: 'ContractSupplyCppa',
      label: 'contracts.types.supply_cppa',
      direction: 'buy',
      permissions: ['display:power_supply'],
    },
    {
      value: 'ContractSupplyUppa',
      label: 'contracts.types.supply_uppa',
      direction: 'buy',
      permissions: ['display:power_supply'],
    },
    {
      value: 'ContractSupplyAuxiliaries',
      label: 'contracts.types.aux',
      direction: 'buy',
      permissions: ['display:power_supply'],
    },
  ],
  GR: [
    { value: 'ContractSoa', label: 'uPPA', direction: 'sell' },
    { value: 'ContractCppa', label: 'cPPA', direction: 'sell' },
    { value: 'ContractGo', label: 'REC', direction: 'sell' },
    { value: 'ContractSwap', label: 'Swap', direction: 'sell' },
    {
      value: 'ContractSupplyCppa',
      label: 'contracts.types.supply_cppa',
      direction: 'buy',
      permissions: ['display:power_supply'],
    },
    {
      value: 'ContractSupplyUppa',
      label: 'contracts.types.supply_uppa',
      direction: 'buy',
      permissions: ['display:power_supply'],
    },
    {
      value: 'ContractSupplyAuxiliaries',
      label: 'contracts.types.aux',
      direction: 'buy',
      disabled: true,
      permissions: ['display:power_supply'],
    },
  ],
  HR: [
    { value: 'ContractSoa', label: 'uPPA', direction: 'sell' },
    {
      value: 'ContractSupplyCppa',
      label: 'contracts.types.supply_cppa',
      direction: 'buy',
      permissions: ['display:power_supply'],
    },
    {
      value: 'ContractSupplyUppa',
      label: 'contracts.types.supply_uppa',
      direction: 'buy',
      permissions: ['display:power_supply'],
    },
    {
      value: 'ContractSupplyAuxiliaries',
      label: 'contracts.types.aux',
      direction: 'buy',
      disabled: true,
      permissions: ['display:power_supply'],
    },
  ],
  IE: [
    { value: 'ContractSoa', label: 'uPPA', direction: 'sell' },
    { value: 'ContractSoa', label: 'uPPA', direction: 'buy' },
    { value: 'ContractCppa', label: 'cPPA', direction: 'sell' },
    { value: 'ContractSwapIe', label: 'CfD RESS', direction: 'sell' },
    { value: 'ContractSwap', label: 'Swap', direction: 'sell' },
    { value: 'ContractGo', label: 'REC', direction: 'sell' },
    {
      value: 'ContractSupplyAuxiliaries',
      label: 'contracts.types.aux',
      direction: 'buy',
      disabled: true,
      permissions: ['display:power_supply'],
    },
  ],
  IT: [
    { value: 'ContractSoa', label: 'uPPA', direction: 'sell' },
    { value: 'ContractCppa', label: 'cPPA', direction: 'sell' },
    { value: 'ContractSwapIt', label: 'CfD FER 2', direction: 'sell' },
    { value: 'ContractSwapIt', label: 'CfD FER 1', direction: 'sell' },
    { value: 'ContractSwapIt', label: 'CfD FER X', direction: 'sell' },
    { value: 'ContractSoa', label: 'Conto Energia', direction: 'sell' },
    { value: 'ContractSwap', label: 'Swap', direction: 'sell' },
    { value: 'ContractGo', label: 'REC', direction: 'sell' },
    {
      value: 'ContractSupplyCppa',
      label: 'contracts.types.supply_cppa',
      direction: 'buy',
      permissions: ['display:power_supply'],
    },
    {
      value: 'ContractSupplyUppa',
      label: 'contracts.types.supply_uppa',
      direction: 'buy',
      permissions: ['display:power_supply'],
    },
    {
      value: 'ContractSupplyAuxiliaries',
      label: 'contracts.types.aux',
      direction: 'buy',
      disabled: true,
      permissions: ['display:power_supply'],
    },
  ],
  KZ: [
    { value: 'ContractSoa', label: 'uPPA', direction: 'sell' },
    {
      value: 'ContractSupplyCppa',
      label: 'contracts.types.supply_cppa',
      direction: 'buy',
      permissions: ['display:power_supply'],
    },
    {
      value: 'ContractSupplyUppa',
      label: 'contracts.types.supply_uppa',
      direction: 'buy',
      permissions: ['display:power_supply'],
    },
    {
      value: 'ContractSupplyAuxiliaries',
      label: 'contracts.types.aux',
      direction: 'buy',
      disabled: true,
      permissions: ['display:power_supply'],
    },
  ],
  ME: [
    { value: 'ContractSoa', label: 'uPPA', direction: 'sell' },
    {
      value: 'ContractSupplyCppa',
      label: 'contracts.types.supply_cppa',
      direction: 'buy',
      permissions: ['display:power_supply'],
    },
    {
      value: 'ContractSupplyUppa',
      label: 'contracts.types.supply_uppa',
      direction: 'buy',
      permissions: ['display:power_supply'],
    },
    {
      value: 'ContractSupplyAuxiliaries',
      label: 'contracts.types.aux',
      direction: 'buy',
      disabled: true,
      permissions: ['display:power_supply'],
    },
  ],
  MX: [
    { value: 'ContractSoa', label: 'uPPA', direction: 'sell' },
    {
      value: 'ContractSupplyCppa',
      label: 'contracts.types.supply_cppa',
      direction: 'buy',
      permissions: ['display:power_supply'],
    },
    {
      value: 'ContractSupplyUppa',
      label: 'contracts.types.supply_uppa',
      direction: 'buy',
      permissions: ['display:power_supply'],
    },
    {
      value: 'ContractSupplyAuxiliaries',
      label: 'contracts.types.aux',
      direction: 'buy',
      disabled: true,
      permissions: ['display:power_supply'],
    },
  ],
  NL: [
    { value: 'ContractSoa', label: 'uPPA', direction: 'sell' },
    {
      value: 'ContractSupplyCppa',
      label: 'contracts.types.supply_cppa',
      direction: 'buy',
      permissions: ['display:power_supply'],
    },
    {
      value: 'ContractSupplyUppa',
      label: 'contracts.types.supply_uppa',
      direction: 'buy',
      permissions: ['display:power_supply'],
    },
    {
      value: 'ContractSupplyAuxiliaries',
      label: 'contracts.types.aux',
      direction: 'buy',
      disabled: true,
      permissions: ['display:power_supply'],
    },
  ],
  PL: [
    { value: 'ContractSoa', label: 'uPPA', direction: 'sell' },
    { value: 'ContractCppa', label: 'cPPA', direction: 'sell' },
    { value: 'ContractGo', label: 'GO', direction: 'sell' },
    { value: 'ContractSwapPl', label: 'Polish CfD', direction: 'sell' },
    { value: 'ContractSwap', label: 'Swap', direction: 'sell' },
    {
      value: 'ContractSupplyCppa',
      label: 'contracts.types.supply_cppa',
      direction: 'buy',
      permissions: ['display:power_supply'],
    },
    {
      value: 'ContractSupplyUppa',
      label: 'contracts.types.supply_uppa',
      direction: 'buy',
      permissions: ['display:power_supply'],
    },
    {
      value: 'ContractSupplyAuxiliaries',
      label: 'contracts.types.aux',
      direction: 'buy',
      disabled: true,
      permissions: ['display:power_supply'],
    },
  ],
  PT: [
    { value: 'ContractSoa', label: 'uPPA', direction: 'sell' },
    { value: 'ContractCppa', label: 'cPPA', direction: 'sell' },
    { value: 'ContractGo', label: 'REC', direction: 'sell' },
    { value: 'ContractSwap', label: 'Swap', direction: 'sell' },
    {
      value: 'ContractSupplyCppa',
      label: 'contracts.types.supply_cppa',
      direction: 'buy',
      permissions: ['display:power_supply'],
    },
    {
      value: 'ContractSupplyUppa',
      label: 'contracts.types.supply_uppa',
      direction: 'buy',
      permissions: ['display:power_supply'],
    },
    {
      value: 'ContractSupplyAuxiliaries',
      label: 'contracts.types.aux',
      direction: 'buy',
      disabled: true,
      permissions: ['display:power_supply'],
    },
  ],
  UK: [
    { value: 'ContractSoa', label: 'uPPA', direction: 'sell' },
    { value: 'ContractCppa', label: 'cPPA', direction: 'sell' },
    { value: 'ContractGo', label: 'REC', direction: 'sell' },
    { value: 'ContractSwapUk', label: 'UK CfD', direction: 'sell' },
    {
      value: 'ContractSupplyCppa',
      label: 'contracts.types.supply_cppa',
      direction: 'buy',
      permissions: ['display:power_supply'],
    },
    {
      value: 'ContractSupplyUppa',
      label: 'contracts.types.supply_uppa',
      direction: 'buy',
      permissions: ['display:power_supply'],
    },
    {
      value: 'ContractSupplyAuxiliaries',
      label: 'contracts.types.aux',
      direction: 'buy',
      disabled: true,
      permissions: ['display:power_supply'],
    },
  ],
  US: [
    { value: 'ContractSoa', label: 'uPPA', direction: 'sell' },
    {
      value: 'ContractSupplyCppa',
      label: 'contracts.types.supply_cppa',
      direction: 'buy',
      permissions: ['display:power_supply'],
    },
    {
      value: 'ContractSupplyUppa',
      label: 'contracts.types.supply_uppa',
      direction: 'buy',
      permissions: ['display:power_supply'],
    },
    {
      value: 'ContractSupplyAuxiliaries',
      label: 'contracts.types.aux',
      direction: 'buy',
      disabled: true,
      permissions: ['display:power_supply'],
    },
  ],
  UY: [
    { value: 'ContractSoa', label: 'uPPA', direction: 'sell' },
    {
      value: 'ContractSupplyCppa',
      label: 'contracts.types.supply_cppa',
      direction: 'buy',
      permissions: ['display:power_supply'],
    },
    {
      value: 'ContractSupplyUppa',
      label: 'contracts.types.supply_uppa',
      direction: 'buy',
      permissions: ['display:power_supply'],
    },
    {
      value: 'ContractSupplyAuxiliaries',
      label: 'contracts.types.aux',
      direction: 'buy',
      disabled: true,
      permissions: ['display:power_supply'],
    },
  ],
  ZA: [
    { value: 'ContractSoa', label: 'uPPA', direction: 'sell' },
    {
      value: 'ContractSupplyCppa',
      label: 'contracts.types.supply_cppa',
      direction: 'buy',
      permissions: ['display:power_supply'],
    },
    {
      value: 'ContractSupplyUppa',
      label: 'contracts.types.supply_uppa',
      direction: 'buy',
      permissions: ['display:power_supply'],
    },
    {
      value: 'ContractSupplyAuxiliaries',
      label: 'contracts.types.aux',
      direction: 'buy',
      disabled: true,
      permissions: ['display:power_supply'],
    },
  ],
};

export type ContractTypeOption = Option<ContractType> & { direction: Contract['direction']; disabled?: boolean };

export const FINANCIAL_CONTRACTS = new Set<ContractType>([
  'ContractCrEdf',
  'ContractSwap',
  'ContractSwapPl',
  'ContractSwapUk',
  'ContractSwapIe',
  'ContractSwapIt',
  'ContractGo',
  'ContractGo-LIGHT',
  'ContractCapa',
  'ContractCapaProxy',
]);

export const CFD_CONTRACTS = ['ContractSwapUk', 'ContractSwapPl', 'ContractSwapIe', 'ContractSwapIt'];
