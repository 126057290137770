import React from 'react';
import { CellContext } from '@tanstack/react-table';
import { Trade } from '@utils/types/trade';

export const Revenue = ({ row: { original } }: CellContext<Trade, unknown>) => {
  if (original?.revenue || original?.revenue === 0) {
    return <div>{original?.revenue?.toFixed(0)} €</div>;
  }

  return null;
};
