import React, { useMemo, useState } from 'react';
import { UserProvider } from '@components/providers/UserProvider';
import { Alert } from '@GDM/Alert';
import { useInvoicesByIdentifier } from '@hooks/requests/meter-invoices/useInvoicesByIdentifier';
import type { Contract } from '@utils/types/contract';
import { User } from '@utils/types/user';
import { InvoiceTableDataWrapper } from 'components/invoices/InvoiceTableDataWrapper';
import dayjs from 'dayjs';

export const Invoices = ({ user, direction }: { user: User; direction: Contract['direction'] | 'all' }) => {
  const params = new URLSearchParams(window.location.search.substring(1));
  const meterName = params.has('meter') ? params.get('meter') : null;
  const installationName = params.has('installation') ? params.get('installation') : null;
  const bookName = params.has('book') ? params.get('book') : null;

  let type: 'meter' | 'book' | 'installation' = 'meter';
  if (bookName) type = 'book';
  if (installationName) type = 'installation';

  const requestParams = useMemo<{ name: string; type: 'book' | 'meter' | 'installation' }>(
    () => ({
      name: meterName || bookName || installationName || '',
      type,
    }),
    [meterName, bookName, installationName, type],
  );

  const identifier = requestParams.name;
  const source_type = requestParams.type;
  // Start date is set to previous november if current month is January
  const defaultStartDate =
    dayjs().month() === 0
      ? dayjs().subtract(1, 'year').month(10).startOf('month').toDate()
      : dayjs().startOf('year').toDate();

  const defaultEndDate = dayjs().endOf('month').toDate();
  const [startDate, setStartDate] = useState<Date | null>(defaultStartDate);
  const [endDate, setEndDate] = useState<Date | null>(defaultEndDate);

  const {
    data: invoices,
    loading,
    error,
  } = useInvoicesByIdentifier({ identifier, direction, source_type, startDate, endDate });

  const handleDateChange = (startDate: Date | null, endDate?: Date | null) => {
    const endOfMonth = endDate ? dayjs(endDate).endOf('month').toDate() : null;

    setStartDate(startDate);
    setEndDate(endOfMonth);
  };

  if (!requestParams.name) {
    return null;
  }

  if (error) {
    return <Alert variant="danger" label="errors.unknown_error" className="ta:center m-4" />;
  }

  return (
    <UserProvider user={user}>
      <InvoiceTableDataWrapper
        invoices={invoices}
        isLight={!bookName && !!meterName}
        sortByDefault="end_date"
        onDateChange={handleDateChange}
        startDate={startDate}
        endDate={endDate}
        isLoading={loading}
        direction={direction}
      />
    </UserProvider>
  );
};
