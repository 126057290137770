import { useEffect, useState } from 'react';
import { useRequest } from '@hooks/useRequest';
import { all_v2_trades_path } from '@utils/routes';
import { Trade } from '@utils/types/trade';

const useTrades = () => {
  const [allTrades, setAllTrades] = useState<Trade[]>([]);

  const req = useRequest<Trade[]>(all_v2_trades_path(), 'GET');

  useEffect(() => {
    if (req?.data) {
      setAllTrades(req.data);
    }
  }, [req.data]);

  return {
    data: allTrades,
    loading: req.loading,
    error: req.error,
  };
};

export default useTrades;
