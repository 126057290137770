import React, { useMemo } from 'react';
import tradesContext, { TradesContextType } from '@context/trades.context';
import useTrades from '@hooks/requests/useTrades';
import { User } from '@utils/types/user';

export const TradesProvider = (
  props: React.PropsWithChildren<{
    user: User;
  }>,
) => {
  const { data: allTrades, loading, error } = useTrades();

  const tradesContextValue = useMemo<TradesContextType>(
    () => ({
      isLoading: loading,
      error: error,
      allTrades: allTrades,
    }),
    [loading, error, allTrades],
  );

  return <tradesContext.Provider value={tradesContextValue}>{props.children}</tradesContext.Provider>;
};
