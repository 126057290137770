import React, { useEffect, useMemo, useState } from 'react';
import { getRevenuesByInvoice, updateRevenuesByInvoice, type UpdateRevenuesByInvoiceBody } from '@api/invoiceRevenues';
import { Button } from '@GDM/Button';
import { Modal } from '@GDM/Modal';
import { Spinner } from '@GDM/Spinner';
import { Text } from '@GDM/Text';
import useTranslation from '@hooks/useTranslation';
import { useMutation, useQuery } from '@tanstack/react-query';
import getContractTypeLabel from '@utils/contract/getLabel';
import { isANumber } from '@utils/isANumber';
import dayjs from 'dayjs';
import round from 'lodash/round';
import { FormProvider, useFieldArray, useForm, useWatch } from 'react-hook-form';
import { useInvoicesContext } from '../invoicesContext';
import { parseRevenuesNumberValues } from '../utils/parseRevenuesNumberValues';
import { parseRevenuesResponseBody } from '../utils/parseRevenuesResponseBody';
import { ConfirmationModal } from './ConfirmationModal';
import { Revenue } from './Revenue';
import { RevenueLabels } from './RevenueLabels';
import { RevenueTotal } from './RevenueTotal';
import { UpdateInvoiceForm } from './update-modal.types';
import { UpdateModalProps } from './UpdateModal';

export const UpdateModalLoadCurve = ({ isOpen, toggle, invoice, direction }: UpdateModalProps) => {
  const { t } = useTranslation();
  const {
    data: allRevenues,
    isSuccess: fetchIsSuccess,
    isPending: fetchIsPending,
    isLoading: fetchIsLoading,
    error: fetchError,
  } = useQuery({
    queryKey: ['revenues', invoice?.uuid],
    queryFn: () => getRevenuesByInvoice(invoice?.uuid || ''),
    enabled: !!invoice,
    initialData: [],
  });
  const updateRevenues = useMutation({
    mutationFn: (data: UpdateRevenuesByInvoiceBody) => updateRevenuesByInvoice(invoice?.uuid || '', data),
    onSuccess: (invoice) => {
      updateInvoice(invoice);
      toggle();
    },
  });
  const form = useForm<UpdateInvoiceForm>({ defaultValues: { revenues: [] } });
  const { reset, control, handleSubmit } = form;
  const arrayForm = useFieldArray({ control, name: 'revenues' });
  const revenues = useWatch({ control, name: 'revenues' });
  const { updateInvoice } = useInvoicesContext();
  const [isConfirmationModalOpen, setIsConfirmationModalOpen] = useState(false);
  const PERCENTAGE_DIFF_TRESHOLD = 10;

  const parsedRevenues = revenues?.map((revenue) => ({ ...revenue, ...parseRevenuesNumberValues(revenue) })) || [];

  const toggleConfirmationModal = () => setIsConfirmationModalOpen(!isConfirmationModalOpen);

  const handleSubmitWithConfirmation = (values: UpdateInvoiceForm) => {
    const needsConfirmation = percentageDiff > PERCENTAGE_DIFF_TRESHOLD;

    if (!needsConfirmation) {
      return handleSubmitCallback(values);
    }

    return setIsConfirmationModalOpen(true);
  };

  const handleSubmitCallback = (values: UpdateInvoiceForm) => {
    const data: UpdateRevenuesByInvoiceBody = {
      invoice_revenues: values.revenues.map((revenue) => {
        const { quantity, unit_price } = parseRevenuesNumberValues(revenue);

        return {
          uuid: revenue.uuid,
          quantity: quantity ?? 0,
          // convert from c{currency}/kWh to {currency}/kWh
          unit_price: isANumber(unit_price) ? unit_price / 100 : 0,
          label: revenue.label || '',
          revenue_type: revenue.revenue_type,
        };
      }),
    };

    const validUuids = values.revenues.filter((revenue) => revenue.uuid).map((revenue) => revenue.uuid);
    const deletedRevenues = allRevenues.filter((revenue) => !validUuids.includes(revenue.uuid));

    deletedRevenues.forEach((revenue) => {
      data.invoice_revenues.push({
        uuid: revenue.uuid,
        quantity: 0,
        unit_price: 0,
        label: revenue.label || '',
        revenue_type: revenue.revenue_type,
        _destroy: 1,
      });
    });

    updateRevenues.mutate(data);
  };

  const totalRevenue =
    parsedRevenues.reduce((acc, revenue) => {
      const quantity = (revenue.quantity ?? 0) / 100;
      const unitPrice = revenue.unit_price ?? 0;
      const total = (quantity * unitPrice).toFixed(2);

      return acc + parseFloat(total);
    }, 0) || 0;
  const totalAmount = invoice?.total_amount ?? 0;
  const percentageDiff = useMemo(() => {
    if (!revenues?.length) return 0;

    return Math.abs(round(((totalRevenue - totalAmount) / totalAmount) * 100, 2));
  }, [totalRevenue, totalAmount, revenues]);

  const isEmpty = !revenues?.length;

  useEffect(() => {
    if (allRevenues && fetchIsSuccess) {
      reset({ revenues: parseRevenuesResponseBody(allRevenues) });
    }
  }, [allRevenues, reset, fetchIsSuccess]);

  useEffect(() => {
    return () => {
      reset();
    };
  }, [reset]);

  const handleAddNew = () => {
    arrayForm.append({ uuid: '', revenue_type: 'total_prod', label: '', quantity: '', unit_price: '' });
  };

  const handleDelete = (index: number) => {
    arrayForm.remove(index);
  };

  if (!invoice) return null;

  return (
    <Modal
      size="xl"
      isOpen={isOpen}
      toggle={toggle}
      header={t('sales_management.invoices.update_invoice_title', {
        installation_name: invoice.installation_name,
        contract_type: t(getContractTypeLabel(invoice.contract_type, invoice.installation_country)),
        end_date:
          dayjs(invoice.end_date).format('MMM YYYY').charAt(0).toUpperCase() +
          dayjs(invoice.end_date).format('MMM YYYY').slice(1),
      })}
      isLoading={fetchIsLoading || updateRevenues.isPending}
      error={fetchError?.message ?? updateRevenues?.error?.message}
      submitAction={handleSubmit(handleSubmitWithConfirmation)}
      submitDisabled={updateRevenues.isPending || isEmpty}
      data-cy="update-invoice-modal"
      info="sales_management.edit_invoice_warning"
    >
      <ConfirmationModal
        isOpen={isConfirmationModalOpen}
        submitAction={handleSubmit(handleSubmitCallback)}
        toggle={toggleConfirmationModal}
        isLoading={fetchIsLoading || updateRevenues.isPending}
        error={fetchError?.message ?? updateRevenues?.error?.message}
        data-cy="confirmation-modal"
      />
      {isEmpty && !fetchIsPending && (
        <Text text="sales_management.no_revenues_warning" type="danger" icon="AlertCircle" multiline />
      )}
      {fetchIsLoading && <Spinner />}
      {!fetchIsPending && (
        <FormProvider {...form}>
          <form onSubmit={handleSubmit(handleSubmitWithConfirmation)}>
            {arrayForm.fields.length > 0 && <RevenueLabels direction={direction} />}
            {arrayForm.fields.map((field, index) => (
              <Revenue key={field.id} index={index} currency={invoice.currency} onDelete={handleDelete} />
            ))}
            <div className="w-full d-flex">
              <Button
                type="button"
                text="common.add_new_line"
                icon="Plus"
                size="xs"
                variant="link"
                className="ml-auto mt-3"
                onClick={handleAddNew}
              />
            </div>
            {arrayForm.fields.length > 0 && <RevenueTotal totalRevenue={totalRevenue} currency={invoice.currency} />}
          </form>
        </FormProvider>
      )}
    </Modal>
  );
};
