import React, { useCallback, useEffect, useState } from 'react';
import { ControlledGroupedSelect } from '@components/FormInputs/ControlledGroupedSelect';
import ControlledInput from '@components/FormInputs/ControlledInput';
import { Restricted } from '@components/Restricted';
import { Button } from '@GDM/Button';
import { Colon } from '@GDM/Colon';
import { Dialog } from '@GDM/Dialog';
import { Modal } from '@GDM/Modal';
import { Spinner } from '@GDM/Spinner';
import { Table, TableBody, TableHead } from '@GDM/Table';
import useTranslation from '@hooks/useTranslation';
import { useTurbines } from '@hooks/useTurbines';
import {
  getCoreRowModel,
  getPaginationRowModel,
  getSortedRowModel,
  useReactTable,
  type CellContext,
} from '@tanstack/react-table';
import { sortOptionsByLabelAsc } from '@utils/sorters';
import type { Option } from '@utils/types/common-types';
import type { Meter } from '@utils/types/meter';
import type { Turbine, Windmill } from '@utils/types/turbine';
import omit from 'lodash/omit';
import { useForm, type UseFormReturn } from 'react-hook-form';
import { CharacteristicsSection } from '../../shared/CharacteristicsSection/CharacteristicsSection';
import {
  useWindmills,
  useWindmillsCreateMutation,
  useWindmillsDestroyMutation,
  useWindmillsUpdateMutation,
} from './useWindmills';

const defaultValues = {
  turbine_id: '',
  power: 0,
  ref_manufacturer: '',
  name: '',
  ref_aggregator: '',
};

export const Turbines = ({ meter }: { meter: Meter }) => {
  const form = useForm<WindmillForm>({
    defaultValues,
  });
  const [createModalOpen, setCreateModalOpen] = useState(false);
  const [selectedWindmillToDelete, setSelectedWindmillToDelete] = useState<Windmill | null>(null);

  const { data: windmills, isLoading } = useWindmills(meter.name);

  const toggleCreateModal = useCallback(
    (windmill: Windmill | null) => {
      setCreateModalOpen((prev) => !prev);

      if (!windmill) form.reset(defaultValues);
      else {
        form.reset({ ...windmill, turbine_id: windmill.turbine.id.toString() });
      }
    },
    [form],
  );

  const toggleDestroyModal = useCallback(
    (windmill: Windmill | null) => {
      setSelectedWindmillToDelete(windmill);
    },
    [setSelectedWindmillToDelete],
  );

  const destroyMutation = useWindmillsDestroyMutation(meter.name, toggleDestroyModal);

  const table = useReactTable({
    columns: [
      { id: 'turbine_name', header: 'common.turbine', accessorFn: (row) => row.turbine.name },
      { accessorKey: 'name', header: 'common.name' },
      { accessorKey: 'power', header: 'common.power_mw' },
      { id: 'manufacturer', header: 'monitoring.turbines.manufacturer', accessorFn: (row) => row.turbine.manufacturer },
      { id: 'diameter', header: 'monitoring.turbines.diameter_m', accessorFn: (row) => row.turbine.diameter },
      {
        id: 'ref',
        header: 'monitoring.turbines.ref',
        cell: RefCell,
      },
      {
        id: 'actions',
        cell: ({ row }) => (
          <div className="d-flex gap-2">
            <Button floating icon="Edit3" onClick={() => toggleCreateModal(row.original)} />
            <Button floating variant="secondary" icon="Trash2" onClick={() => toggleDestroyModal(row.original)} />
          </div>
        ),
      },
    ],
    data: windmills || [],
    sortDescFirst: false,
    getSortedRowModel: getSortedRowModel(),
    getPaginationRowModel: getPaginationRowModel(),
    getCoreRowModel: getCoreRowModel(),
  });

  return (
    <Restricted permissions={['display:view_windmill']}>
      <CharacteristicsSection
        title={
          <Restricted permissions={['display:create_windmill']}>
            <CharacteristicsSection.Title title="common.turbines">
              <Button
                variant="primary-2"
                size="xs"
                icon="Plus"
                text="common.add_new"
                onClick={() => toggleCreateModal(null)}
              />
            </CharacteristicsSection.Title>
          </Restricted>
        }
      >
        <TurbinesModal isOpen={createModalOpen} toggle={toggleCreateModal} installationName={meter.name} form={form} />
        <DeleteModal
          isOpen={Boolean(selectedWindmillToDelete)}
          toggle={toggleDestroyModal}
          confirmAction={() =>
            selectedWindmillToDelete?.id ? destroyMutation.mutate(selectedWindmillToDelete.id) : undefined
          }
        />

        {isLoading && <Spinner size="sm" />}

        <div>
          <Table hasActions>
            <TableHead table={table} />
            <TableBody table={table} isLoading={isLoading} />
          </Table>
        </div>
      </CharacteristicsSection>
    </Restricted>
  );
};

export type WindmillForm = Pick<
  Windmill,
  'id' | 'turbine_id' | 'power' | 'ref_manufacturer' | 'name' | 'ref_aggregator'
>;

const TurbinesModal = ({
  form,
  isOpen,
  toggle,
  installationName,
}: {
  form: UseFormReturn<WindmillForm>;
  isOpen: boolean;
  toggle: (windmill: Windmill | null) => void;
  installationName: string;
}) => {
  const { data: turbines, loading: isLoading } = useTurbines();
  const { watch, setValue } = form;
  const isEdit = Boolean(watch('id'));
  const createMutation = useWindmillsCreateMutation(installationName, toggle);
  const updateMutation = useWindmillsUpdateMutation(installationName, toggle);

  const groupedOptions = getTurbineOptions(turbines || []);

  const handleSubmit = (data: WindmillForm) => {
    if (data.id) {
      updateMutation.mutate(data);
    } else {
      createMutation.mutate(data);
    }
  };

  useEffect(() => {
    const subscribtion = watch(({ turbine_id }, event) => {
      if (event.name === 'turbine_id' && turbine_id && event.type === 'change') {
        const selectedTurbine = turbines?.find((turbine) => turbine.id.toString() === turbine_id);

        if (selectedTurbine?.maximum_power) {
          setValue('power', selectedTurbine.maximum_power);
        }
      }
    });

    return () => {
      subscribtion.unsubscribe();
    };
  }, [watch, setValue, turbines]);

  const loading = createMutation.isPending || isLoading;

  return (
    <Modal
      isOpen={isOpen}
      toggle={() => toggle(null)}
      submitAction={form.handleSubmit(handleSubmit)}
      header={isEdit ? 'monitoring.turbines.edit_wind_turbine' : 'monitoring.turbines.add_wind_turbine'}
      isLoading={loading}
    >
      <div className="d-flex flex-column gap-3">
        <ControlledGroupedSelect
          control={form.control}
          label="common.turbine"
          name="turbine_id"
          options={groupedOptions}
          isLoading={isLoading}
          size="lg"
          rules={{ required: true }}
        />
        <ControlledInput
          control={form.control}
          label="common.power"
          name="power"
          type="number"
          suffix="kW"
          size="lg"
          full
        />
        <ControlledInput control={form.control} label="common.name" name="name" size="lg" full />
        <ControlledInput
          control={form.control}
          label="monitoring.turbines.ref_manufacturer"
          name="ref_manufacturer"
          size="lg"
          full
        />
        <ControlledInput
          control={form.control}
          label="monitoring.turbines.ref_aggregator"
          name="ref_aggregator"
          size="lg"
          full
        />
      </div>
    </Modal>
  );
};

const DeleteModal = ({
  isOpen,
  toggle,
  confirmAction,
}: {
  isOpen: boolean;
  toggle: (windmill: Windmill | null) => void;
  confirmAction: () => void;
}) => {
  return (
    <Dialog
      isOpen={isOpen}
      message="monitoring.turbines.are_you_sure_delete"
      toggle={() => toggle(null)}
      confirmAction={confirmAction}
    />
  );
};

const getTurbineOptions = (turbines: Turbine[]) => {
  const groupedOptionsMap: { [manufacturer: string]: Option[] } = {};

  for (const value of turbines) {
    const manufacturer = value.manufacturer || 'common.misc';

    if (groupedOptionsMap[manufacturer]) {
      groupedOptionsMap[manufacturer].push({ label: value.name, value: value.id.toString() });
    } else {
      groupedOptionsMap[manufacturer] = [{ label: value.name, value: value.id.toString() }];
    }
  }

  const optionsWithoutMisc = omit(groupedOptionsMap, 'common.misc');

  const sortedOptions = Object.keys(optionsWithoutMisc)
    .map((manufacturer) => ({
      label: manufacturer,
      options: groupedOptionsMap[manufacturer].sort(sortOptionsByLabelAsc),
    }))
    .sort(sortOptionsByLabelAsc);

  const miscOption = {
    label: 'common.misc',
    options: (groupedOptionsMap['common.misc'] || []).sort(sortOptionsByLabelAsc),
  };

  return [...sortedOptions, miscOption];
};

const RefCell = ({ row }: CellContext<Windmill, unknown>) => {
  const { t } = useTranslation();

  return (
    <div>
      {row.original.ref_manufacturer && (
        <div>
          {t('monitoring.turbines.ref_manufacturer')}
          <Colon /> <span className="fw:400">{row.original.ref_manufacturer}</span>
        </div>
      )}
      {row.original.ref_aggregator && (
        <div>
          {t('monitoring.turbines.ref_aggregator')}
          <Colon /> <span className="fw:400">{row.original.ref_aggregator}</span>
        </div>
      )}
    </div>
  );
};
