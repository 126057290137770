import React, { useMemo } from 'react';
import { useUser } from '@context/User.context';
import { Badge } from '@GDM/Badge';
import { SELECT_COMPONENTS, sectionStyles } from '@GDM/forms';
import { Icon, OtherAvailableFlags } from '@GDM/Icon';
import { type OptionWithMarketPlayerType } from '@pages/Contracts/Contract/Form/sections/GeneralInfo/hooks/useBuySellOptions';
import { ContractFormQueries } from '@pages/Contracts/Contract/hooks/useContractForm/useContractFormQueries';
import { iconDictionary } from '@utils/constants';
import { COUNTRY_CODES } from '@utils/constants/countries';
import { getSymbol } from '@utils/currency/getSymbol';
import { sortOptionsByLabelAsc } from '@utils/sorters';
import { EnergyType, Option as TOption } from '@utils/types/common-types';
import { ContractForm, ContractType } from '@utils/types/contract';
import { CountryCode } from '@utils/types/countries';
import { InstallationWithMeterInfo } from '@utils/types/installation';
import type Installation from '@utils/types/installation';
import type { User } from '@utils/types/user';
import classNames from 'classnames';
import { Control, type UseFormReturn } from 'react-hook-form';
import { GroupBase, OptionProps } from 'react-select';
import { EnumInput } from '../../../components/Inputs/Enum';
import { ListInput } from '../../../components/Inputs/List';
import { TextInput } from '../../../components/Inputs/Text';
import { getDefaultDirection } from '../../../helpers/getDefaultDirection';
import installationStyles from '../styles/installation-picker.module.scss';
import { noSpacesIfHasCegedimActive } from '../validators';
import { CppaMarketPlayers } from './CppaMarketPlayer';

export const MainContractInfo = ({
  control,
  invoicingPlatform,
  readOnly,
  queries: { marketPlayers, installations, intermediaries },
  installation,
  contractType,
  buyerOptions,
  sellerOptions,
  direction,
  form,
}: {
  control: Control<ContractForm>;
  invoicingPlatform: ContractForm['invoicing_platform'];
  readOnly: boolean;
  queries: Pick<ContractFormQueries, 'marketPlayers' | 'installations' | 'intermediaries'>;
  installation: InstallationWithMeterInfo | undefined | null;
  contractType: ContractType | null;
  buyerOptions: OptionWithMarketPlayerType[];
  sellerOptions: OptionWithMarketPlayerType[];
  direction: ContractForm['direction'];
  form: UseFormReturn<ContractForm>;
}) => {
  const marketPlayersAreNotAvailable = !installation || marketPlayers.isError || marketPlayers.isPending;
  const { currencies, account_type } = useUser();

  const installationsOptions =
    installations.data?.map(({ name, uuid, energy, country, status }) => ({
      label: name,
      value: uuid,
      energy,
      country,
      status,
    })) || [];

  const currenciesOptions = useMemo(() => {
    return currencies
      .map((currency) => {
        const symbol = getSymbol(currency);
        const label = currency !== symbol ? `${currency} (${symbol})` : currency;

        return { value: currency, label };
      })
      .sort(sortOptionsByLabelAsc);
  }, [currencies]);

  return (
    <>
      <div className={classNames(sectionStyles.row, sectionStyles['two-per-row'])}>
        <TextInput
          id="ContractNumber"
          name="contract_nb"
          control={control}
          rules={{
            pattern: { value: /^[A-Za-z0-9-_ ]+$/, message: 'errors.only_letters_numbers' },
            validate: {
              noSpacesIfHasCegedimActive: (contractNb: ContractForm['contract_nb'] | undefined) =>
                noSpacesIfHasCegedimActive(contractNb, invoicingPlatform),
            },
          }}
          label="sales_management.contract_nb"
          size="lg"
          readOnly={readOnly}
          data-cy="contract-number"
          full
          helpBox={{ title: 'sales_management.contract_nb', text: 'contracts.info.contract_nb' }}
        />
        <ListInput
          id="InstallationPicker"
          name="installation_uuid"
          control={control}
          options={installationsOptions}
          isLoading={installations.isPending}
          rules={{ required: true }}
          components={{ Option: InstallationOption<string | null> }}
          formatOptionLabel={FormattedOption}
          label="sales_management.installation"
          isSearchable
          classNamePrefix="select-installation"
          readOnly={readOnly}
          defaultValue={null}
        />
      </div>
      <div className={classNames(sectionStyles.row)}>
        <EnumInput
          control={control}
          id="ContractDirection"
          name="direction"
          label="sales_management.contract_direction"
          rules={{ required: true }}
          containerClassName="fit"
          defaultValue={getDefaultDirection(account_type)}
          options={[
            { label: 'sales_management.i_buy', value: 'buy' },
            { label: 'sales_management.i_sell', value: 'sell' },
          ]}
          size="lg"
          readOnly={readOnly}
          afterChange={() => {
            form.setValue('type', null);
          }}
        />
        <ListInput
          id="SellerPicker"
          name="seller_id"
          control={control}
          options={sellerOptions}
          rules={{ required: true }}
          isLoading={intermediaries.isPending}
          label="sales_management.seller"
          classNamePrefix="select-seller"
          noOptionsMessage="sales_management.no_seller"
          isDisabled={
            (!installation || !!intermediaries.error || intermediaries.isPending || sellerOptions.length <= 1) &&
            !readOnly
          }
          isSearchable
          readOnly={readOnly}
          helpBox={{ title: 'sales_management.seller', text: getSellerHelpBox(account_type, direction) }}
        />
        <ListInput
          id="BuyerPicker"
          name="buyer_id"
          control={control}
          options={buyerOptions}
          rules={{ required: true }}
          isLoading={marketPlayers.isPending}
          label="sales_management.buyer"
          isDisabled={
            marketPlayersAreNotAvailable ||
            !!intermediaries.error ||
            intermediaries.isPending ||
            buyerOptions.length <= 1
          }
          noOptionsMessage="sales_management.no_buyer_for_country"
          classNamePrefix="select-buyer"
          readOnly={readOnly}
          isSearchable
          helpBox={{ title: 'sales_management.buyer', text: getBuyerHelpBox(account_type, direction) }}
        />
      </div>
      <div className={classNames(sectionStyles.row, sectionStyles['two-per-row'])}>
        <ListInput
          id="CurrencyPicker"
          name="currency"
          control={control}
          options={currenciesOptions}
          rules={{ required: true }}
          label="sales_management.currency"
          readOnly={readOnly}
          classNamePrefix="contract-currency"
          defaultValue="EUR"
        />
        {contractType === 'ContractCppa' && (
          <CppaMarketPlayers
            control={control}
            isDisabled={marketPlayersAreNotAvailable}
            readonly={readOnly}
            options={buyerOptions}
            isLoading={marketPlayers.isPending}
          />
        )}
      </div>
    </>
  );
};

type ExtendedOption<T = string> = TOption<T> & {
  country?: CountryCode;
  energy?: EnergyType;
  status?: Installation['status'];
};

const InstallationOption = <T extends string | null>(
  props: OptionProps<ExtendedOption<T>, boolean, GroupBase<ExtendedOption<T>>>,
) => {
  return (
    <SELECT_COMPONENTS.Option {...props}>
      <FormattedOption {...props.data} />
    </SELECT_COMPONENTS.Option>
  );
};

const FormattedOption = <T extends string | null>(
  data: TOption<T> & { country?: CountryCode; energy?: EnergyType; status?: Installation['status'] },
): JSX.Element => {
  const country = data.country;
  const validCountries = COUNTRY_CODES;
  const countryIcon = country && validCountries.includes(country) ? country : OtherAvailableFlags.XX;

  return (
    <div className={installationStyles.container} title={data.label}>
      <Icon name={countryIcon} size={12} className="mr-2" />
      <Icon name={iconDictionary[data.energy || 'other']} size={14} />
      <span className={classNames(installationStyles.label, 'ml-2')}>{data.label}</span>
      {data.status === 'draft' && <Badge className="ml-2" label="common.draft" variant="danger" />}
    </div>
  );
};

const getSellerHelpBox = (account_type: User['account_type'], direction: ContractForm['direction']) => {
  if (direction === 'buy' && account_type === 'producer') {
    return 'contracts.info.buyer';
  }

  if (direction === 'buy') {
    return 'contracts.info.select_aggregator_or_intermediary';
  }

  return 'contracts.info.seller';
};

const getBuyerHelpBox = (account_type: User['account_type'], direction: ContractForm['direction']) => {
  if (direction === 'buy') {
    return 'contracts.info.seller';
  }

  return 'contracts.info.buyer';
};
