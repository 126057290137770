import React from 'react';
import tradesContext from '@context/trades.context';
import Page from '@pages/Page';
import { User } from '@utils/types/user';
import { TradesProvider } from './context';
import { TableWrapper } from './Table';

export const Trades = ({ user }: { user: User }) => {
  return (
    <TradesProvider user={user}>
      <tradesContext.Consumer>
        {(context) => (
          <Page
            title="sales_management.trades"
            error={context.error?.message}
            isLoading={context.isLoading}
            user={user}
            layout="no-background"
            fixedHeightContainer
          >
            <TableWrapper />
          </Page>
        )}
      </tradesContext.Consumer>
    </TradesProvider>
  );
};
