import { createContext, useContext } from 'react';
import { RequestError } from '@hooks/useRequest';
import { Trade } from '@utils/types/trade';

export type TradesContextType = {
  isLoading?: boolean;
  error?: RequestError | null;
  allTrades: Trade[];
};

const tradesContext = createContext<TradesContextType>({
  allTrades: [],
});

export const useConsumeTrades = (): TradesContextType => useContext(tradesContext);

export default tradesContext;
