import { createMeter, getMeterById, updateMeter } from '@api/meters';
import type { RequestErrorBody } from '@hooks/useRequest';
import { useMutation, useQuery } from '@tanstack/react-query';
import type { Meter } from '@utils/types/meter';
import type { AxiosError } from 'axios';
import type { InstallationForm } from './installation.types';
import { formatMeterBody } from './utils/formMeterBody';

export const useMeterFormQuery = ({ id, setMeterId }: { id?: Meter['id']; setMeterId?: (id?: number) => void }) => {
  const query = useQuery<Meter, AxiosError<RequestErrorBody>>({
    queryKey: ['meter', id],
    queryFn: () => getMeterById(id),
    enabled: !!id,
  });

  const createMutation = useMutation<Meter, AxiosError<RequestErrorBody>, InstallationForm>({
    mutationFn: (body) => createMeter(formatMeterBody(body)),
    onSuccess: (data) => {
      setMeterId?.(data.id);
    },
  });

  const updateMutation = useMutation<Meter, AxiosError<RequestErrorBody>, InstallationForm & { meterId: number }>({
    mutationFn: (body) => updateMeter(body.meterId, formatMeterBody(body)),
    onSuccess: (data) => {
      setMeterId?.(data.id);
    },
  });

  return { query, createMutation, updateMutation };
};
