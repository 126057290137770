import { Option } from '@utils/types/common-types';
import { NEG_PRICE_RULE_NAMES } from '@utils/types/contract/constants/neg-price-rule-names';

export type NegPriceRule = typeof NEG_PRICE_RULE_NAMES[number];

const OPTIONS_MAP: Record<NegPriceRule, Option<NegPriceRule>> = {
  zero: { label: 'sales_management.neg_price_rule_zero', value: 'zero' },
  spot: { label: 'sales_management.neg_price_rule_spot', value: 'spot' },
  imbalance_pos: { label: 'sales_management.neg_price_rule_imbalance_pos', value: 'imbalance_pos' },
  imbalance_pos_with_fees: {
    label: 'sales_management.neg_price_rule_imbalance_pos_with_fees',
    value: 'imbalance_pos_with_fees',
  },
  imbalance_pos_with_2_euro_fees: {
    label: 'sales_management.neg_price_rule_imbalance_pos_with_2_euro_fees',
    value: 'imbalance_pos_with_2_euro_fees',
  },
  m0: { label: 'sales_management.neg_price_rule_m0', value: 'm0' },
  same_as_positive_prices: {
    label: 'sales_management.neg_price_rule_same_as_positive_prices',
    value: 'same_as_positive_prices',
  },
};

export const NEG_PRICE_RULE_CONTRACT_OPTIONS_MAP: Record<string, Option<NegPriceRule>[]> = {
  ContractCr: [
    OPTIONS_MAP.zero,
    OPTIONS_MAP.spot,
    OPTIONS_MAP.imbalance_pos,
    OPTIONS_MAP.imbalance_pos_with_fees,
    OPTIONS_MAP.imbalance_pos_with_2_euro_fees,
    OPTIONS_MAP.m0,
  ],
  ContractSoa: [
    OPTIONS_MAP.zero,
    OPTIONS_MAP.spot,
    OPTIONS_MAP.imbalance_pos,
    OPTIONS_MAP.imbalance_pos_with_fees,
    OPTIONS_MAP.imbalance_pos_with_2_euro_fees,
    OPTIONS_MAP.same_as_positive_prices,
  ],
  ContractSwap: [OPTIONS_MAP.zero, OPTIONS_MAP.same_as_positive_prices],
  ContractSwapIe: [OPTIONS_MAP.zero],
};

export const DEFAULT_PRICE_RULES_OPTIONS = Object.values(OPTIONS_MAP);
