import React from 'react';
import ControlledSelect from '@components/FormInputs/ControlledSelect';
import { useInternalFormContext } from '@GDM/forms';
import { useFormDataProviders } from '@pages/Installations/Installation/hooks/useFormDataProviders';
import { useInstallationForm } from '@pages/Installations/Installation/hooks/useInstallationForm';

export const SignTypeSelect = ({ disabled }: { disabled?: boolean }) => {
  const form = useInstallationForm();
  const { country } = useInternalFormContext();
  const dataProviders = useFormDataProviders(form.watch);
  const selectedDataProviderId = form.watch('data_provider_id');
  const selectedDataProvider = dataProviders.find(({ id }) => id === selectedDataProviderId)?.name.toLowerCase();

  const isEnedisLike =
    country === 'FR' &&
    !!selectedDataProvider &&
    !['rte', 'électricité de strasbourg (és)', 'synelva'].includes(selectedDataProvider);

  const options = isEnedisLike
    ? [
        { value: 'online', label: 'common.e_signature' },
        { value: 'offline', label: 'common.manuel' },
      ]
    : [{ value: 'offline', label: 'common.manuel' }];

  return (
    <ControlledSelect
      control={form.control}
      disabled={disabled}
      options={options}
      label="common.signature"
      name="mandate.sign_type"
      size="lg"
    />
  );
};
